<script>
import mapboxgl from 'mapbox-gl'

export default {
  name: 'MapboxControls',
  props: {
    position: { type: String, default: 'top-right' }
  },

  created () {
    this.$nextTick(() => {
      const { map } = this.$parent
      map.on('load', () => {
        map.addControl(new mapboxgl.NavigationControl(), this.position)
      })
    })
  },

  beforeDestroy () {
    const { map } = this.$parent
    map.on('load', () => {
    })
  },

  render (h) {
    return null
  }
}
</script>
