<script>
import mapboxgl from 'mapbox-gl'

export default {
  name: 'MapboxScale',

  created () {
    this.$nextTick(() => {
      const { map } = this.$parent
      map.on('load', () => {
        map.addControl(new mapboxgl.ScaleControl({ maxWidth: 200 }))
      })
    })
  },

  beforeDestroy () {
    const { map } = this.$parent
    map.on('load', () => {
    })
  },

  render (h) {
    return null
  }
}
</script>
