<template>
  <div class="wrapper fit">
    <div id="map" class="fit">
      <slot />
    </div>
  </div>
</template>

<script>
import mapboxgl from 'mapbox-gl'

export default {
  name: 'MapboxMap',
  data () {
    return {
      map: null
    }
  },

  mounted () {
    this.map = new mapboxgl.Map({
      container: 'map', // container id
      style: 'mapbox://styles/blouze/cjue5mx4h09s01fqrvqk7o8pm', // stylesheet location
      center: [2.349014, 48.864716], // starting position [lng, lat]
      zoom: 6, // starting zoom
      maxBounds: [[-5.225, 41.0], [9.65, 51.2]] // restrict pannnigng to France
    })
    this.map.on('load', () => { this.$emit('load') })
  }
}
</script>

<style lang="stylus">
.wrapper
  position: relative

#map
  position: absolute
  top: 0
  bottom: 0
</style>
