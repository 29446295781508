<template>
  <div class="column fit">
    <MapboxMap @load="onMapLoaded">
      <MapboxClusters :markers="markersCollection" :mapProps="mapProps" :colors="['#C0A7EB', '#A78ED2', '#8D74B8']">
        <template slot-scope="marker">
          <h3>{{marker.name}}</h3>
        </template>
      </MapboxClusters>

      <MapboxControls />

      <MapboxScale />
    </MapboxMap>
  </div>
</template>

<style>
</style>

<script>
import { MapboxMap, MapboxClusters, MapboxControls, MapboxScale } from '../../components/mapbox'
import { mapGetters } from 'vuex'

export default {
  name: 'EntityMap',
  components: { MapboxMap, MapboxClusters, MapboxControls, MapboxScale },

  data () {
    return {
    }
  },

  computed: {
    ...mapGetters({
      mapEntities: 'entities/getMapEntities',
      entity: 'entities/getCurrentEntity'
    }),

    mapProps () {
      let walletCenterTo = null
      if (this.entity) {
        for (let i = 0; i < this.entity.wallets.length; i++) {
          let wallet = this.entity.wallets[i]
          if (wallet.LNG && wallet.LAT) {
            walletCenterTo = wallet
            break
          }
        }
      }

      return {
        centerToCluster: true,
        zoom: 8,
        center: walletCenterTo ? [Number(walletCenterTo.LNG), Number(walletCenterTo.LAT)] : [2.349014, 48.864716], // starting position [lng, lat]
        clusterId: 'unclustered-point-primary'
      }
    },

    markersCollection () {
      let features = []
      this.mapEntities.forEach(entity => {
        entity.wallets.filter(wallet => wallet.LNG && wallet.LAT).forEach(wallet => {
          let featureSingleWallet = {
            'type': 'Feature',
            'geometry': {
              'type': 'Point',
              'coordinates': [Number(wallet.LNG), Number(wallet.LAT)]
            },
            'properties': {
              'id': entity.id,
              'label': entity.label,
              'sousLabel': wallet.NOMPTF,
              'to': { name: 'entity-show', params: { id: entity.id } },
              'marker-symbol': 'monument',
              [entity.id === this.entity.id ? 'isPrimaryFeature' : 'empty']: entity.id === this.entity.id
            }
          }

          features.push(featureSingleWallet)
        })
      })

      return {
        'type': 'FeatureCollection',
        'features': features
      }
    }
  },

  mounted () {
    this.$q.loading.show()
  },

  methods: {
    onMapLoaded () {
      this.$q.loading.hide()
      this.fetchMapEntities()
    },
    fetchMapEntities () {
      this.$q.loading.show()
      this.$store.dispatch('entities/fetchMapEntities', {})
        .finally(() => { this.$q.loading.hide() })
    }
  }
}
</script>
