<script>
import mapboxgl from 'mapbox-gl'

export default {
  name: 'MapboxCluster',
  props: ['markers', 'mapProps', 'colors'],

  data () {
    return {
      popup: null,
      isDataLoaded: false
    }
  },

  created () {
    this.$nextTick(() => {
      const { map } = this.$parent
      map.on('load', () => {
        map.addSource('markers', {
          type: 'geojson',
          data: this.markers,
          cluster: true,
          clusterMaxZoom: 16,
          clusterRadius: 50
        })

        const centerToPrimaryPoints = () => {
          var features = map.queryRenderedFeatures({ layers: ['unclustered-point-primary'] })
          if (features.length === 0) return

          var coordinates = features.map(feature => feature.geometry.coordinates)
          if (!coordinates.length) return

          var bounds = coordinates.reduce((bounds, coord) => bounds.extend(coord), new mapboxgl.LngLatBounds())
          map.fitBounds(bounds, {
            padding: 100,
            maxZoom: 15
          })

          if (this.isDataLoaded) map.off('idle', centerToPrimaryPoints)
        }

        map.on('idle', centerToPrimaryPoints)

        map.addLayer({
          id: 'clusters',
          type: 'circle',
          source: 'markers',
          filter: ['has', 'point_count'],
          paint: {
            'circle-color': [
              'case', ['==', ['get', 'isPrimaryFeature'], true], '#483bfb',
              ['step', ['get', 'point_count'],
                this.colors[0], 10,
                this.colors[1], 30,
                this.colors[2]
              ]
            ],
            'circle-radius': [
              'step',
              ['get', 'point_count'],
              20, 10,
              30, 25,
              40
            ]
          }
        })

        map.on('mouseenter', 'clusters', e => {
          map.getCanvas().style.cursor = 'pointer'
        })

        map.on('click', 'clusters', e => {
          var features = map.queryRenderedFeatures(e.point, { layers: ['clusters'] })
          var clusterId = features[0].properties.cluster_id

          map.getSource('markers').getClusterExpansionZoom(clusterId, (err, zoom) => {
            if (err) return

            map.easeTo({ center: features[0].geometry.coordinates, zoom: zoom })
          })
        })

        map.on('mouseleave', 'clusters', e => {
          map.getCanvas().style.cursor = ''
        })

        map.addLayer({
          id: 'cluster-count',
          type: 'symbol',
          source: 'markers',
          filter: ['has', 'point_count'],
          paint: {
            'text-color': 'white'
          },
          layout: {
            'text-field': '{point_count_abbreviated}',
            'text-font': ['DIN Offc Pro Medium', 'Arial Unicode MS Bold'],
            'text-size': 18
          }
        })

        map.addLayer({
          id: 'unclustered-point-primary',
          type: 'circle',
          source: 'markers',
          filter: [
            'all',
            ['none', ['has', 'point_count']],
            ['has', 'isPrimaryFeature']
          ],
          paint: {
            'circle-color': '#1F9BD3',
            'circle-radius': 8,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
          }
        }, 'place-neighborhood-suburb-label')

        map.addLayer({
          id: 'unclustered-point',
          type: 'circle',
          source: 'markers',
          filter: [
            'all',
            ['none', ['has', 'point_count']],
            ['!has', 'isPrimaryFeature']
          ],
          paint: {
            'circle-color': this.colors[2],
            'circle-radius': 8,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
          }
        }, 'place-neighborhood-suburb-label')

        // Popup management
        // - Popup for primary
        let popups = []
        map.on('zoomend', () => {
          const zoomLevel = map.getZoom()

          var features = map.queryRenderedFeatures({ layers: ['unclustered-point-primary'] })

          console.log(features)

          // Show labels for zoom levels 7 and higher, hide them for lower zoom levels
          if (zoomLevel >= 7) {
            features.forEach((feature) => {
              var coordinates = feature.geometry.coordinates.slice()

              const label = feature.properties.label
              const sousLabel = feature.properties.sousLabel
              const newLabelHtml = `
                <div>
                  ${label}
                  ${sousLabel ? '<br /><strong>' + sousLabel + '</strong>' : ''}
                </div>
              `

              var popup = new mapboxgl.Popup({
                closeButton: false,
                closeOnClick: false
              })
                .setLngLat(coordinates)
                .setHTML(newLabelHtml)
                .addTo(map)

              popups.push(popup)
            })
          } else {
            popups.forEach((popup) => {
              popup.remove()
            })
            popups = []
          }
        })

        // - Popup for non primary
        this.popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        })

        map.on('mouseenter', 'unclustered-point', e => {
          map.getCanvas().style.cursor = 'pointer'
          const feature = e.features[0]

          var coordinates = feature.geometry.coordinates.slice()

          const label = feature.properties.label
          const sousLabel = feature.properties.sousLabel
          const newLabelHtml = `
            <div>
              ${label}
              ${sousLabel ? '<br /><strong>' + sousLabel + '</strong>' : ''}
            </div>
          `

          this.popup.setLngLat(coordinates)
            .setHTML(newLabelHtml)
            .addTo(map)
        })
        map.on('mouseleave', 'unclustered-point', () => {
          map.getCanvas().style.cursor = ''
          this.popup.remove()
        })
        // End Popup

        map.on('click', 'unclustered-point', e => {
          let to = JSON.parse(e.features[0].properties.to)
          this.$router.push(to)
        })
      })
    })
  },

  beforeDestroy () {
    const { map } = this.$parent
    map.on('load', () => {
      map.removeSource('markers')
    })
  },

  watch: {
    markers: {
      handler (newVal, oldVal) {
        const { map } = this.$parent
        const source = map.getSource('markers')
        if (source) {
          source.setData(newVal)
          if (this.mapProps) {
            map.setZoom(this.mapProps.zoom)
            map.setCenter(this.mapProps.center)
          }

          this.isDataLoaded = true
        }
      }
    }
  },

  render (h) {
    return null
  }
}
</script>
