<template>
  <div ref="marker" />
</template>

<script>
import mapboxgl from 'mapbox-gl'

export default {
  name: 'mapbox-marker',
  props: ['marker'],

  created () {
    this.$nextTick(() => {
      const { map } = this.$parent
      map.on('load', () => {
        map.addLayer({
          id: 'unique-point',
          type: 'circle',
          source: 'marker',
          filter: ['!', ['has', 'point_count']],
          paint: {
            'circle-color': '#8D74B8',
            'circle-radius': 8,
            'circle-stroke-width': 1,
            'circle-stroke-color': '#fff'
          }
        }, 'place-neighborhood-suburb-label')

        this.popup = new mapboxgl.Popup({
          closeButton: false,
          closeOnClick: false
        })
      })
    })
  },

  beforeDestroy () {
    const { map } = this.$parent
    map.on('load', () => {
    })
  }
}
</script>
